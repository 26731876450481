import { useFetcher } from "@remix-run/react";
import {
  createContext,
  useCallback,
  useContext,
  type PropsWithChildren,
} from "react";
import { Theme } from "~/lib/enums";

export function useToggleTheme(): () => void {
  const fetcher = useFetcher();
  const theme = useContext(ThemeContext);

  return useCallback(() => {
    const newTheme: Theme = theme === Theme.Dark ? Theme.Light : Theme.Dark;
    fetcher.submit(
      { theme: "" + newTheme, action: "set-theme" },
      { method: "post", action: "/app/user/settings" }
    );
  }, [fetcher, theme]);
}

export function useTheme(): Theme {
  return useContext(ThemeContext);
}

export function ThemeProvider({
  theme,
  children,
}: PropsWithChildren<{ theme: Theme }>) {
  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
}

type ThemeContextType = Theme;
const ThemeContext = createContext<ThemeContextType>(Theme.Dark);
